$code-background-color: #FAFAFA !default;

@import
  "minima/skins/classic",
  "minima/initialize";

.related {
  border-top: 1px solid $border-color-01
}

.highlight
{
  border: 1px solid $border-color-02; 
  border-radius: 0;
}

.highlight pre
{
  border: 0; 
  border-radius: 0;
  margin-bottom: 0;
}

.highlighter-rouge
{
  border: 0;
  background-color: #F2F2F2;
}

.codeHeader {
  box-sizing: content-box;
  background-color: #F2F2F2;
  display: flex;
  flex-direction: row;
  font-size: .8rem;
  border: 1px solid $border-color-02;
  border-bottom: 0;
  margin-top: 16px;
  min-height: 28px
}

.codeHeader>.language {
  padding: 4px 12px;
  flex-grow: 1;
}
  
.codeHeader>.action {
  padding: 0px 10px;
  padding-top: 1px;
  background-color: transparent;
  border: 0 solid $border-color-02;
  border-radius: 0;
  border-left-width: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: normal;
  font-size: 0.8em;
}

.codeHeader>.action:hover {
  background-color: $code-background-color;
}

.codeHeader>.action:focus {
  outline: 0;
}

.codeHeader>.action .successful-copy-alert {
  background-color: rgb(18, 135, 18);
  color: white;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  font-size: 1.8em;
  justify-content: center;
  align-items: center;
}

.codeHeader>.action .successful-copy-alert.is-transparent {
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.is-relative {
  position: relative!important;
}

img {
  border: solid 1px lightgray;
}
